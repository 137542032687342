import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
import { isMobile, isTablet } from "react-device-detect";
import BackgroundImage from 'gatsby-background-image-es5';

/**
 * Assets
 */
import './assets/styles/_index.scss';
import Mute from './assets/img/mute.svg';


const VideoEle = ({ video, image, autoplay, mute, align, height, width, contain, imageContain, mobileHeight, className }) => {
  const videoRef = useRef();
  const [ isContained, setIsContained ] = useState(false);
  const [ playing, setPlaying ] = useState(false);
  const [ pause, setPause ] = useState(false);
  const [ muted, setMuted ] = useState(false);
  const [ currentHeight, setCurrentHeight ] = useState(height);
  const handlePlaying = () => {
    videoRef.current.play();
    setPlaying(!playing)
    setPause(c => !c)
  };
  const handleMute = () => setMuted(!muted);
  const handlePause = () => {
    if(pause){
      videoRef.current.pause();
    } else {
      videoRef.current.play();
    }
    setPause(c => !c)
  };

  useEffect(() => {
    if(autoplay && !isMobile){
      setPause(c => !c)
      setPlaying((c) => !c);
    }
    if(contain){
      setIsContained(true);
    }
    if((isMobile || isTablet) && mobileHeight){
      setCurrentHeight(mobileHeight)
    } else if(isMobile) {
      setCurrentHeight('auto')
    }
  }, [autoplay, mute, contain, mobileHeight]);
  const muteComponent = mute && (
    <span 
      className={classNames('video__mute', {'is-muted': muted})} 
      onClick={handleMute} 
      onKeyDown={handleMute} 
      role="button" 
      tabIndex={0}
    >
      <Mute>Mute</Mute>
    </span> 
  )

  return (
    <div 
      className={classNames(`video video--${align}`, className, {'is-contained': isContained, 'is-playing': playing})} 
      style={{'height': currentHeight}}
    >

      <BackgroundImage
        Tag="section"
        fluid={image}
        style={{'width': isContained ? '100%' : width }}
        className={classNames('video__inner', {'contain-image': imageContain})} 
        fadeIn={true}
      >
        <video 
          src={video} 
          autoPlay={autoplay} 
          muted={muted} 
          ref={videoRef}
          onEnded={() => {
            setPlaying(false);
            setPause(false);
          }}
        />
        <span 
          className={classNames('video__play')} 
          onClick={handlePlaying} 
          onKeyDown={handlePlaying} 
          role="button" 
          tabIndex={0}
        >
          Play
        </span>
        {playing && (
          <span 
            className={classNames('video__pause', {'is-paused': pause})} 
            onClick={handlePause} 
            onKeyDown={handlePause} 
            role="button" 
            tabIndex={0}
          >
            Play
          </span>
        )}
        {muteComponent}
      </BackgroundImage>
    </div>
  )
}

export default VideoEle;
