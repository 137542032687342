import React from "react"
import { openPopupWidget } from 'react-calendly';
import { Button } from 'react-bootstrap';
 
const CalendlyTwo = ({ url, prefill, pageSettings, utm ,label}) => {
  const onClick = () => openPopupWidget({ url, prefill, pageSettings, utm });

  if(label == null) {
  	var label = "Book a Video Call"
  }
  else {
  	var label = label
  }
 
  return (
  <Button onClick={onClick} className="btn btn-outline-primary" >{label}</Button>
  )
}

export default CalendlyTwo;
